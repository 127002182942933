import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MapComponent from "./Components/Map";
import AddProduct from "./Components/Map/AddProduct";
import EditProduct from "./Components/Map/EditProduct";
import ProductInfo from "./Components/Map/ProductInfo";
import Signup from "./Components/Map/Signup";
import Signin from "./Components/Map/Signin";
import Searchbar from "./Components/Map/Searchbar";
import Login from "./Components/Map/Login";
import GoogleForm from "./Components/Map/GoogleForm";
function App() {
  return (
    <div className="app-wrapper">
      <BrowserRouter>
        <Routes>
          <Route index element={<MapComponent />} />
          <Route path="/addproduct" element={<AddProduct />} />
          <Route path="/editproduct" element={<EditProduct />} />
          <Route path="/productinfo" element={<ProductInfo />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/searchbar" element={<Searchbar />} />
          <Route path="/login" element={<Login />} />
          <Route path="/googleform" element={<GoogleForm />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
