import React, { useState } from "react";
import signup1 from "../icons/signup1.png";
import { useNavigate, redirect } from "react-router-dom"; // Import useHistory
import "./Signup.css";
//import GoogleAuth from "../Map/GoogleAuth";
//import { FcGoogle } from "react-icons/fc";

import GoogleForm from "./GoogleForm";

const Signup = () => {
  const navigate = useNavigate();

  const [notification, setNotification] = useState(null);

  const handleSignup = () => {
    if (!validateFields()) {
      setNotification("Please fill in all required fields.");
      return;
    }
    // Navigate to the signin route
    navigate("/signin");
  };

  const validateFields = () => {
    const nameInput = document.querySelector('input[type="text"]');
    const emailInput = document.querySelector('input[type="email"]');
    const passwordInput = document.querySelector('input[type="password"]');

    let isValid = true;

    if (!nameInput.value.trim()) {
      setNotification("Please enter your name.");
      isValid = false;
    }

    if (!emailInput.value.trim()) {
      setNotification("Please enter your email.");
      isValid = false;
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(emailInput.value.trim())) {
        setNotification("Please enter a valid email address.");
        isValid = false;
      }
    }

    if (!passwordInput.value.trim()) {
      setNotification("Please enter your password.");
      isValid = false;
    }

    return isValid;
  };

  return (
    <div className="page-container">
      {notification && <div className="notification">{notification}</div>}
      <div className="page-section">
        <>
          <div className="signup-section">
            <img src={signup1} alt="signup" className="signup-image" />
          </div>

          <div className="signup-form-section">
            <div className="signup-form-title">
              <h4>GFOKK</h4>
            </div>
            <div className="get-started">
              <h2>Get Started</h2>
            </div>
            <p className="signup-form-para">
              Already have an account?<a href="signin">Sign in</a>
            </p>
            <form>
              <div className="signup-form-label">
                <input
                  type="text"
                  placeholder="Name"
                  id="nameInput"
                  name="nameInput"
                />
                <input
                  type="email"
                  placeholder="Email"
                  id="emailInput"
                  name="emailInput"
                />
                <input
                  type="password"
                  placeholder="Password"
                  id="passwordInput"
                  name="passwordInput"
                />
              </div>
            </form>
            <p className="signup-form-para1">Check this if you are a seller.</p>
            <input type="checkbox" className="checkbox" />
            <div className="signup-button">
              <button type="button" onClick={handleSignup}>
                Signup
              </button>
              <p className="separator">OR</p>
              {/*<button type="button">
                <FcGoogle size={17} className="google-icon" />
                Continue with Google
  </button>*/}
              <GoogleForm />

              <p className="signup-para">
                By Signing up, I agree to the
                <span className="underline-text">Terms of Service</span> and
                <span className="underline-text">Privacy Policy</span>
              </p>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Signup;

{
  /*import React, { useState } from "react";
import signup1 from "../icons/signup1.png";
import { useNavigate } from "react-router-dom";
import "./Signup.css";
import GoogleAuth from "./GoogleAuth";
import AddProduct from "../Map/AddProduct"; // Import AddProduct component

const Signup = () => {
  const navigate = useNavigate();
  const [notification, setNotification] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track authentication status

  const handleSignup = () => {
    if (!validateFields()) {
      setNotification("Please fill in all required fields.");
      return;
    }
    navigate("/signin");
  };

  const validateFields = () => {
    const nameInput = document.querySelector('input[type="text"]');
    const emailInput = document.querySelector('input[type="email"]');
    const passwordInput = document.querySelector('input[type="password"]');

    let isValid = true;

    if (!nameInput.value.trim()) {
      setNotification("Please enter your name.");
      isValid = false;
    }

    if (!emailInput.value.trim()) {
      setNotification("Please enter your email.");
      isValid = false;
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(emailInput.value.trim())) {
        setNotification("Please enter a valid email address.");
        isValid = false;
      }
    }

    if (!passwordInput.value.trim()) {
      setNotification("Please enter your password.");
      isValid = false;
    }

    return isValid;
  };

  const handleGoogleSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleGoogleFailure = (error) => {
    console.error("Google login failed!", error);
  };

  return (
    <div className="page-container">
      {notification && <div className="notification">{notification}</div>}
      <div className="page-section">
        <>
          <div className="signup-section">
            <img src={signup1} alt="signup" className="signup-image" />
          </div>

          <div className="signup-form-section">
            <div className="signup-form-title">
              <h4>GFOKK</h4>
            </div>
            <div className="get-started">
              <h2>Get Started</h2>
            </div>
            <p className="signup-form-para">
              Already have an account?<a href="signin">Sign in</a>
            </p>
            <form>
              <div className="signup-form-label">
                <input
                  type="text"
                  placeholder="Name"
                  id="nameInput"
                  name="nameInput"
                />
                <input
                  type="email"
                  placeholder="Email"
                  id="emailInput"
                  name="emailInput"
                />
                <input
                  type="password"
                  placeholder="Password"
                  id="passwordInput"
                  name="passwordInput"
                />
              </div>
            </form>
            <p className="signup-form-para1">Check this if you are a seller.</p>
            <input type="checkbox" className="checkbox" />
            <div className="signup-button">
              <button type="button" onClick={handleSignup}>
                Signup
              </button>
              <p className="separator">OR</p>
              {!isLoggedIn ? <GoogleAuth /> : <AddProduct />}
            </div>
            <p className="signup-para">
              By Signing up, I agree to the
              <span className="underline-text">Terms of Service</span> and
              <span className="underline-text">Privacy Policy</span>
            </p>
          </div>
        </>
      </div>
    </div>
  );
};

export default Signup;*/
}
