import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
const GoogleForm = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      // navigate("/");
      navigate("/AddProduct");
    },

    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
    navigate("/");
  };

  return (
    <div>
      {/*<h2>React Google Login</h2>
      <br />
  <br />*/}
      {/* {profile ? ( */}
      <div>
        {/*<img src={profile.picture} alt="user image" />
          <h3>User Logged in</h3>
          <p>Name: {profile.name}</p>
          <p>Email Address: {profile.email}</p>
          <br />
          <br />*/}
        {/* <button onClick={logOut}>Log out</button>*/}
        {/* Render AddProduct component when profile is not null */}
        {/*<AddProduct />*/}
      </div>
      {/* ) : ( */}
      <button onClick={login}>
        <FcGoogle size={17} />
        Continue With Google
      </button>
      {/* )} */}
    </div>
  );
};
export default GoogleForm;
