import React, { useState, useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import "./Searchbar.css";

const Searchbar = () => {
  const [input, setInput] = useState(""); //search bar
  const [selectedResult, setSelectedResult] = useState(""); // selected result
  const [results, setResults] = useState([]); // search results
  const [isUserInput, setIsUserInput] = useState(false); // To track user input

  useEffect(() => {
    if (input.trim() !== "") {
      setIsUserInput(true);
      fetchData(input);
    } else {
      setIsUserInput(false);
      setResults([]);
    }
  }, [input]);

  const fetchData = (value) => {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((response) => response.json())
      .then((json) => {
        const filteredResults = json.filter((user) => {
          return (
            user &&
            user.name &&
            user.name.toLowerCase().includes(value.toLowerCase())
          );
        });
        setResults(filteredResults);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setResults([]);
      });
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSelectResult = (name) => {
    setInput(name);
    setSelectedResult(name);
    setResults([]);
  };

  const handleClearInput = () => {
    setInput("");
    setSelectedResult("");
    setResults([]);
  };

  return (
    <div className="searchbar-input-wrapper">
      <FaSearch id="searchbar-search-icon" />
      <input
        placeholder={isUserInput ? "" : "Type to Search..."}
        className="searchbar-input"
        value={input}
        onChange={handleChange}
      />
      {input && (
        <div className="cancel-icon" onClick={handleClearInput}>
          <FaTimes />
        </div>
      )}
      <div className="search-results">
        {results.length > 0 &&
          results.map((user) => (
            <div
              key={user.id}
              onClick={() => handleSelectResult(user.name)}
              style={{
                display: selectedResult !== user.name ? "block" : "none",
              }}
            >
              {user.name}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Searchbar;
