import React, { useState, useEffect } from "react";
import "./Signin.css";
import signup1 from "../icons/signup1.png";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import AddProduct from "./AddProduct";
import GoogleForm from "./GoogleForm";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  const handleSignin = () => {
    if (!validateFields()) {
      setNotification("Please fill in all required fields.");
      return;
    }
    navigate("/addproduct");
  };
  const validateFields = () => {
    const emailInput = document.querySelector('input[type="email"]');
    const passwordInput = document.querySelector('input[type="password"]');

    let isValid = true;

    if (!emailInput.value.trim()) {
      setNotification("Please enter your email.");
      isValid = false;
    } else {
      // Email validation using regular expression
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(emailInput.value.trim())) {
        setNotification("Please enter a valid email address.");
        isValid = false;
      }
    }

    if (!passwordInput.value.trim()) {
      setNotification("Please enter your password.");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleGoogleLogin = () => {
    window.gapi.auth2.getAuthInstance().signIn();
  };
  useEffect(() => {
    // Load Google API client library
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.onload = () => {
      // Initialize Google API client
      window.gapi.load("auth2", () => {
        window.gapi.auth2.init({
          client_id:
            "382056697611-7ac95lkfhietojh90eqqh22rfm3j4cgh.apps.googleusercontent.com",
        });
      });
    };
    document.body.appendChild(script);
  });
  return (
    <div className="signin-page-container">
      {notification && (
        <div className="signin-notification">{notification}</div>
      )}
      <div className="signin-page-section">
        <div className="signin-section">
          <img src={signup1} alt="signup" className="signin-image" />
        </div>
        <div className="signin-form-section">
          <div className="signin-form-title">
            <h4>GFOKK</h4>
          </div>
          <div className="welcome-back">
            <h2>Welcome Back</h2>
          </div>
          <p className="signup-form-para">
            Not registered yet?<a href="signup">Sign Up</a>
          </p>
          <form onSubmit={handleSubmit}>
            <div className="signin-form-label">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <p className="error-message">{emailError}</p>}
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <p className="error-message">{passwordError}</p>
              )}
            </div>
            <div className="signup-button">
              <button type="submit" onClick={handleSignin}>
                Sign In
              </button>

              <p className="separator">OR</p>
              <GoogleForm />
              {/* <FcGoogle */}
              {/* className="signin-google-icon" */}
              {/* onClick={handleGoogleLogin} */}
              {/* /> */}
              {/* <button type="button" onClick={handleGoogleLogin}> */}
              {/* Continue with Google */}
              {/* </button> */}
            </div>
          </form>
          <p className="signin-para">
            By Signing up, I agree to the
            <span className="underline-text">Terms of Service</span> and
            <span className="underline-text">Privacy Policy</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signin;
